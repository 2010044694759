import React, { useState } from "react"
import { FaChevronCircleRight } from "react-icons/fa"
import { graphql } from "gatsby"

import BlogCard from "./blog/BlogCard"
import NoContent from "./NoContent"

const DisplayPosts = ({ posts = [] }) => {
  const [postCount, setpostCount] = useState(3)
  let sortedPosts = []
  posts.forEach(post => {
    const { publishedDate, _createdAt } = post.node

    // assign new property to the date value (publishedDate if it's provided, else default to _createdAt)
    const date = publishedDate || _createdAt
    post.node.newDate = date
  })

  // const dateToNum = d => {
  //   d = d.split("/")
  //   return Number(d[2] + d[1] + d[0])
  // }

  // https://stackoverflow.com/a/37996937
  // sortedPosts = posts
  //   .slice()
  //   .sort((a, b) => new Date(a.node.newDate) - new Date(b.node.newDate))

  // use slice so as not to re-order the original postsArray
  sortedPosts = posts
    .slice()
    .sort((a, b) => (a.node.newDate < b.node.newDate ? 1 : -1))

  let postsToDisplay = sortedPosts.slice(0, postCount)
  const increasePostCount = () => setpostCount(postCount + 5)

  return (
    <div>
      {postsToDisplay.length >= 1 ? (
        <div className="my-4 ">
          <div className="grid gap-y-4 ">
            {postsToDisplay.map((post, index) => {
              const {
                id,
                title,
                summary,
                publishedDate,
                _createdAt,
                slug,
                featureImage,
              } = post.node
              // check if featureImage exists on query, if so, add fluid property, if not assign null
              //Wasn't working: const fluid = featureImage.asset ? post.node.featureImage.asset.fluid: null
              let fluid = null
              if (featureImage == null || featureImage.asset == null) {
                fluid = null
              } else {
                fluid = featureImage.asset.fluid
              }
              return (
                <BlogCard
                  title={title}
                  text={summary}
                  date={publishedDate || _createdAt}
                  handle={slug.current}
                  fluid={fluid}
                  blogIndex={index}
                  key={id}
                />
              )
            })}
          </div>
          {posts.length > postsToDisplay.length && (
            <button
              className="mx-auto my-8 flex text-white font-bold font-heading "
              onClick={() => increasePostCount()}
            >
              {/* Needs to be replaced with SVG */}

              <span className={`bg-red-light pt-2 pb-1 px-4`}>
                + More Posts
              </span>
            </button>
          )}
        </div>
      ) : (
        <NoContent>
          <h2 className="text-gray-dark">No posts currently</h2>
        </NoContent>
      )}
    </div>
  )
}

export const query = graphql`
  fragment DisplayPostsFragment on SanityBlogPost {
    title
    slug {
      current
    }
    summary
    _createdAt(formatString: "MM/DD/YYYY")
    publishedDate(formatString: "MM/DD/YYYY")
    featureImage {
      altText
      asset {
        assetId
        fluid {
          ...GatsbySanityImageFluid
        }
      }
    }
  }
`

export default DisplayPosts
